<template>
    <div class="row">
        <div class="col-4 offset-7 ">
            <div class="pull-right" style="color: red">Скидки не суммируются!</div>
        </div>
        <table class="table col-10 offset-1">
            <thead>
            <tr >
                <th>Наименование</th>
                <th>Применяется</th>
                <th>Условия</th>
                <th>Мин возраст</th>
                <th>Макс возраст</th>
                <th>Скидка</th>
                <th>Дейстие</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="discount in discounts" :key="discount.id" class="hover" >
                <td @click="go(discount.id)">{{discount.name}}</td>
                <td @click="go(discount.id)">{{discount.applyTo === 0 ? 'День' : 'Ночь'}}</td>
                <td @click="go(discount.id)">{{conditions(discount.conditions)}}</td>
                <td @click="go(discount.id)">{{discount.minAge}}</td>
                <td @click="go(discount.id)">{{discount.maxAge}}</td>
                <td @click="go(discount.id)">{{discount.amount}}</td>
                <td @click="remove(discount.id)"><i class="fa fa-trash-o"></i></td>
            </tr>
            </tbody>
        </table>

        <div class="row col-10 offset-1">
            <router-link class="btn btn-primary col-1 offset-10" to="/admin/discount/0">Добавить</router-link>
        </div>
    </div>

</template>

<script>
  export default {
    name: 'discounts',
    data: function () {
      return {
        discounts: []
      }
    },
    async mounted() {
      const { body } = await this.getData('/admin/discounts');
      this.discounts = body;
    },

    methods: {
      getValue(conditions, bit) {
        return (conditions & this.getMask(bit)) > 0;
      },
      conditions: function (conditions) {
        let strs = [];
        if (this.getValue(conditions, 1)) {
          strs.push('Член КЕМО');
        }
        if (this.getValue(conditions, 2)) {
          strs.push('Предварительная регистрация');
        }

        return strs.join(', ');
      },
      go: function (id) {
        this.$router.push(`/admin/discount/${id}`);
      },
      remove: async function(id) {
        id = Number.parseInt(id);
        await this.deleteData(`/admin/discount/${id}`);
        this.discounts = this.discounts.filter(item => item.id !== id);
      }
    }
  }
</script>

<style lang="scss" scoped>
    .fa-trash-o{
        color: red;
    }
</style>
