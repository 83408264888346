<template>
  <div class="container">
    <div class="row">
      <table class="table">
        <tbody>
          <tr class="form-group">
            <td>Название</td>
            <td colspan="3" class="form-group ">
              <input
                type="text"
                class="form-control "
                v-model.trim="data.rtrname"
              />
            </td>
          </tr>
          <tr>
            <th>Начало</th>
            <td>
              <date-component
                v-model="stdate"
                class="input form-control form-control-sm"
                v-bind:time="false"
              ></date-component>
            </td>
            <th>Окончание</th>
            <td>
              <date-component
                v-model="endate"
                class="input form-control form-control-sm"
                v-bind:time="false"
              ></date-component>
            </td>
          </tr>
          <tr>
            <th>Начало<br />пред.регистрации</th>
            <td>
              <date-component
                v-model="prereg_stdate"
                class="input form-control form-control-sm"
                v-bind:time="false"
              ></date-component>
            </td>
            <th>Окончание<br />пред.регистрации</th>
            <td>
              <date-component
                v-model="prereg_endate"
                class="input form-control form-control-sm"
                v-bind:time="false"
              ></date-component>
            </td>
          </tr>
          <tr>
            <th>Место проведения</th>
            <td colspan="3">
              <input
                type="text"
                class="form-control"
                v-model.trim="data.place"
              />
            </td>
          </tr>
          <tr>
            <th>Стоимость зала</th>
            <td>
              <input
                type="number"
                class="form-control"
                v-model.number="data.hallrent"
              />
            </td>
            <th>Стоимость ночлега</th>
            <td>
              <input
                type="number"
                class="form-control"
                v-model.number="data.aprent"
              />
            </td>
          </tr>
          <tr>
            <th>Стоимость бейджа</th>
            <td>
              <input
                type="number"
                class="form-control"
                v-model.number="data.bage"
              />
            </td>
            <th>Стоимость переводчика</th>
            <td>
              <input
                type="number"
                class="form-control"
                v-model.number="data.translater"
              />
            </td>
          </tr>
          <tr>
            <th>Лимит людей в день</th>
            <td>
              <input
                type="number"
                class="form-control"
                v-model.number="data.dailyLimit"
              />
            </td>
            <th></th>
            <td></td>
          </tr>
          <tr>
            <td colspan="6" align="right">
              <button class="btn btn-success" type="submit" @click="save">
                Сохранить
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      data: {},
      rid: 0
    };
  },
  methods: {
    save: async function() {
      this.$loading(true);
      console.log(this.data);
      try {
        if (this.rid > 0) {
          await this.putData(`/admin/retrts/${this.rid}`, this.data);
        } else {
          await this.postData(`/admin/retrts`, this.data);
        }
        this.$loading(false);

        this.$router.push('/admin/retrts');
      } catch (e) {
        console.error(e);
      }
    }
  },
  async mounted() {
    this.$loading(true);

    this.rid = this.$route.params.id;

    if (this.rid > 0) {
      const { body } = await this.getData(`/admin/retrts/${this.rid}`);
      this.data = body;
    } else {
      const now = this.$moment().format('YYYY-MM-DD');
      this.data = {
        stdate: now,
        endate: now,
        prereg_stdate: now,
        prereg_endate: now,
        hallrent: 50,
        aprent: 300,
        bage: 5,
        translater: 100,
        dailyLimit: 300
      };
    }
    this.$loading(false);
  },
  computed: {
    stdate: {
      get: function() {
        return this.$moment(this.data.stdate).format('YYYY-MM-DD');
      },
      set: function(v) {
        this.data.stdate = this.$moment(v).format('YYYY-MM-DD');
        if (this.stdate > this.endate) {
          this.endate = this.stdate;
        }
      }
    },
    endate: {
      get: function() {
        return this.$moment(this.data.endate).format('YYYY-MM-DD');
      },
      set: function(v) {
        this.data.endate = this.$moment(v).format('YYYY-MM-DD');
      }
    },
    prereg_stdate: {
      get: function() {
        return this.$moment(this.data.prereg_stdate).format('YYYY-MM-DD');
      },
      set: function(v) {
        this.data.prereg_stdate = this.$moment(v).format('YYYY-MM-DD');
      }
    },
    prereg_endate: {
      get: function() {
        return this.$moment(this.data.prereg_endate).format('YYYY-MM-DD');
      },
      set: function(v) {
        this.data.prereg_endate = this.$moment(v).format('YYYY-MM-DD');
      }
    }
  }
};
</script>
