<template>
  <b-container>
    <b-row>
      <button @click='toggle()'>Поиск базы</button>
    </b-row>
    <b-row style="height: 100vh">
      <b-col v-show="searchOpen" class="col-4 offset-0 bazas">
        <div class="form-group">
          <input v-model.trim="bazaFilter" class="form-control"/>
        </div>
        <ul>
          <li
                  v-for="baza in getBazas"
                  :key="baza.id"
                  class="baza"
          >{{baza.name}} ({{baza.city}})
          </li>
        </ul>
      </b-col>
      <b-col class="right">
        <b-container>
          <b-row>
            <b-col
                    v-for="(night, index) in nights"
                    :key="night"
            >
              <p class="headTable">
                {{night}}
              </p>
              <p v-for="baza in bazaToShow[index]" :key="baza.id">
                {{`${baza.baza} мест (${baza.mest})`}}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
    export default {
        data: function () {
            return {
                bazas: [],
                bazaFilter: '',
                nights: {},
                retBazas: {},
                bazaToShow: {},
                searchOpen: false
            }
        },
        async mounted() {
            const bazas = await this.getData(`/admin/bazas`)// здесь список всех баз существующих вообще
            this.bazas = bazas.body
            const retBazas = await this.getData('/admin/retrts/bazas') // здесь только те базы, что уже участвуюет в этом ретрите
            this.retBazas = retBazas.body

            console.log('retBazas >>', this.retBazas)

            const { body: { stdate, dayCount } } = await this.getData('/stat/retrts/dayCount')
            const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
            this.nights = {}
            for (let cc = 1; cc < dayCount; cc++) {
                this.nights[cc] = daysOfWeek[this.$moment(stdate).add(cc - 1, 'days').weekday()]
            }
            this.retBazas.forEach((el) => {
                const { night } = el
               this.bazaToShow[night]
                    ? this.bazaToShow[night].push(el)
                    : this.bazaToShow[night] = [el]
            })

            console.log('bazaToShow >>', this.bazaToShow)

            console.log('this.nights >>', this.nights)
        },
        computed: {
            getBazas() {
                if (this.bazaFilter.length > 0) {
                    const r = new RegExp(this.bazaFilter, 'i')
                    return this.bazas.filter(item => item.name.match(r))
                }
                console.log('this.bazas >>', this.bazas)
                return this.bazas
            }
        },
        methods: {
            toggle: function () {
                this.searchOpen = !this.searchOpen
            }
        }
    }
</script>

<style lang="scss" scoped>
  @import "../../variables";

  div {
    font-size: .8rem;
  }

  .bazas {
    border-right: 1px #ccc dot-dash;
    height: 100vh;

    & li {
      cursor: pointer;

    }

    & li:hover {
      background-color: $hovered;
    }
  }

  .right {
    background-color: aliceblue;

    & td {
      text-align: center;
      border: 1px dotted black;

      & .night {
        border-bottom: 1px dotted #777;
      }
    }
  }

  .selectedBazas {
    & ul {
      text-align: left;
      list-style-type: none;
      margin-left: 0; /* Отступ слева в браузере IE и Opera */
      padding-left: 0; /* Отступ слева в браузере Firefox, Safari, Chrome */
    }
  }
  .headTable {
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid black;
  }

</style>
