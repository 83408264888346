<template>
    <div class="container" >
        <div class="float-right" style="padding-bottom: 5px">
            <a class="btn btn-primary" href="retrts/0">Новый</a>
        </div>
        <h3>Список ретритов</h3>
        <table class="table" >

            <thead>
            <tr>
                <th>ID</th>
                <th>Ретрит</th>
                <th>Место проведения</th>
                <th>Начало</th>
                <th>Окончание</th>
            </tr>
            </thead>
            <tbody>

            <tr
                    v-bind:data-id="row.id"
                    class="hover"
                    @click="edit"
                    v-for="row in rows"
                    v-bind:key="row.id"
            >
                <td>{{row.id}}</td>
                <td>{{row.rtrname}}</td>
                <td>{{row.place}}</td>
                <td>{{$moment(row.stdate).format('DD.MM.YYYY')}}</td>
                <td>{{$moment(row.endate).format('DD.MM.YYYY')}}</td>
            </tr>

            </tbody>
        </table>

    </div>

</template>

<script>
    export default {
      data: function() {
        return {
          rows: []
        }
      },
      methods: {
        edit: function (event) {
          const rid = event.target.closest('tr').getAttribute('data-id');
          this.$router.push(`/admin/retrts/${rid}`);
        }
      },
      async mounted() {
        const { body } = await this.getData(`/admin/retrts`);
        this.rows = body;
      }
    }

</script>
