<template>
    <div class="row">
        <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 ">
            <div class="explanation">
                Коды СМС соответствующие нужной части ретрита<br >
                <code>Вносите изменения только в случае, если Вы понимаете, что делаете.</code>
            </div>
            <button class="btn btn-primary" @click="add">Новый код</button>

            <table class="table" style="margin-top: 15px">
                <thead>
                <tr>
                    <td width="80px">Код</td>
                    <td class="b-r">Ретрит</td>
                    <td>Название</td>
                    <td>Дни</td>
                    <td>Ночи</td>
                    <td >&nbsp;</td>
                </tr>
                </thead>
                <tbody>
                    <tr
                            v-for="(item, index) in codes"
                            :key="index"
                            :data-index="index"
                    >
                        <td >
                            <div v-if="inEditMode !== index">{{item.code}}</div>
                            <div v-else><input class="form-control form-control-sm" v-model.trim="item.code" :disabled="!item.isNew" /></div>
                        </td>
                        <td class="b-r">
                            <div v-if="inEditMode !== index">{{item.rtrname}}</div>
                            <div v-else>
                                <select v-model="item.rid" class="form-control form-control-sm">
                                    <option
                                            v-for="item in retrts"
                                            :value="item.id"
                                            :key="item.id"

                                    >{{item.rtrname}}</option>
                                </select>
                            </div>
                        </td>
                        <td class="b-r">
                            <div v-if="inEditMode !== index">{{item.name}}</div>
                            <div v-else><input class="form-control form-control-sm" v-model.trim="item.name"  /></div>
                        </td>

                        <td class="b-r">

                            <div v-if="inEditMode !== index">{{item.days}}</div>
                            <div v-else>
                                <input class="form-control form-control-sm" v-model="item.days" />
                            </div>

                        </td>
                        <td class="b-r">
                            <div v-if="inEditMode !== index">{{item.nights}}</div>
                            <div v-else>
                                <input class="form-control form-control-sm" v-model="item.nights" />
                            </div>
                        </td>
                        <td width="220px">
                            <div class="action btn-group" v-if="inEditMode !== index">
                                <button @click="edit" class="btn btn-sm btn-outline-primary">Редактировать</button>
                                &nbsp;
                                <button  @click="safeRemove"  class="btn btn-sm btn-outline-danger">Удалить</button>
                            </div>
                            <div class="btn-group" style="float: right" v-else>
                                <button @click="cancel" class="btn btn-sm btn-outline-secondary">Отмена</button>
                                &nbsp;
                                <button  @click="save"  class="btn btn-sm btn-outline-success">Сохранить</button>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <app-modal v-if="willRemoveCode" @close="closeConfirm" @confirm="remove">
            <div>Подтвердите удаление кода {{willRemoveCode}}</div>
        </app-modal>
    </div>
</template>

<script>
  import appModal from '@/components/user/modal';

  export default {
    data: function () {
      return {
        codes: [],
        inEditMode: null,
        retrts: [],
        willRemoveCode: null
      }
    },
    methods: {
      async init() {
        let { body } = await this.getData('/admin/codes');
        this.codes = body;
        ({ body } = await this.getData(`/admin/retrts`));
        this.retrts = body;
      },

      async edit(e) {
        const tr = e.target.closest('tr');
        this.inEditMode = Number.parseInt(tr.getAttribute('data-index').trim());
      },
      safeRemove() {
        const index = Number.parseInt(event.target.closest('tr').getAttribute('data-index').trim());
        this.willRemoveCode = this.codes[index].code;
      },
      async remove() {
        await this.deleteData(`/admin/codes/${this.willRemoveCode}`);
        this.willRemoveCode = null;
        this.init();
      },
      closeConfirm() {
        this.willRemoveCode = null;
      },
      cancel() {
        this.inEditMode = null;
        this.codes = this.codes.filter(item => !item.isNew);
      },
      async save() {
        const code = this.codes[this.inEditMode].code;
        const postData = {
          rid: this.codes[this.inEditMode].rid,
          name: this.codes[this.inEditMode].name,
          days: this.codes[this.inEditMode].days,
          nights: this.codes[this.inEditMode].nights
        };
        if (!this.codes[this.inEditMode].isNew) {
          await this.patchData(`/admin/codes/${code}`, postData);
        } else {
          postData.code = code;
          await this.postData(`/admin/codes`, postData);
        }
        this.init();
        this.inEditMode = null;
      },
      add() {
        const newData = {
          code: '',
          rid: 0,
          days: '',
          nights: '',
          name: '',
          isNew: true
        };
        this.inEditMode = this.codes.length;
        this.codes.push(newData);
      }
    },
    mounted() {
      this.init();
    },
    components: {
      appModal
    }
  }
</script>

<style lang="scss" scoped>
    .explanation{
        color: #777;
        font-size: 0.9rem;
        padding-top: 5px;
        padding-bottom: 30px;
    }
    thead tr{
        background-color: #f7f7f7;
    }
    tbody tr{
        & .action {
            display: none;
        }
    }
    tbody tr:hover .action {
        display: block;
        float: right;
    }

    .b-r{
        border-right: 1px #ddd dotted;
    }

</style>
