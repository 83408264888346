<template>
    <div class="row">

        <div class="col-md-2 offset-md-2 ">
            <router-link class="btn btn-primary" to="/admin/editAdmin/0" >Добавить</router-link>
        </div>
        <div class="col-md-4 offset-md-4 form-check">
            <input @change="load" v-model="all" type='checkbox' class="form-check-input" id="adminsAll"/>

            <label for="adminsAll" class="form-check-label">показывать всех</label>
        </div>
        <div class="col-md-8 offset-md-2" style="margin-top: 15px">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>ID</th>
                    <th>login</th>
                    <th>UID</th>
                    <th>Ф.И.О.</th>
                    <th>Ретрит</th>
                    <th>Сумма</th>
                    <th>Права</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(admin,index) in admins"
                        v-bind:data-id="admin.id"
                        @click="edit"
                        class="hover"
                        :class="{striked: !admin.active}"
                        v-bind:key="admin.id"
                >
                    <td >{{index+1}}</td>
                    <td >{{admin.id}}</td>
                    <td>{{admin.login}}</td>
                    <td >{{admin.uid}}</td>
                    <td>{{admin.fullname}}</td>
                    <td>{{admin.retreat}}</td>
                    <td >{{admin.money}}</td>
                    <td >{{admin.permitions}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>
  export default {
    data: function () {
      return {
        admins: [],
        all: false
      }
    },
    methods: {
      async load() {
        this.$loading(true);
        const { body } = await this.getData(`/admin/admins?all=${this.all}`);
        this.admins = body;
        this.$loading(false);
      },
      edit(event) {
        this.$router.push(`/admin/editAdmin/${event.target.closest('[data-id]').getAttribute('data-id')}`);
      }
    },
    mounted() {
      this.load();
    }
  }

</script>

<style scoped>
    .striked{
        text-decoration: line-through;
        font-family: Courier;
    }
</style>
