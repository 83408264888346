<template>

    <form @submit.prevent="save">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="form-group">
                    <label>Login</label>
                    <input type="text" v-model="admin.login" v-bind:readonly="exists" class="form-control"
                           autocomplete="off">
                </div>
                <div class="form-group">
                    <label>Пароль</label>
                    <input type="password" v-model="admin.secret" class="form-control" autocomplete="off">
                </div>
                <div class="form-group">
                    <label>UID</label> (<span v-html="admin.fullname">Ф.И.О</span>)
                    <input type="number" v-model="admin.uid" v-bind:readonly="exists" class="form-control"
                           autocomplete="off">
                </div>
                <div class="form-group">
                    <div class="form-group">
                        <label>Ретрит</label>
                        <select
                                class="form-control"
                                v-model="admin.rid"
                        >
                            <option
                                    v-for="retreat in retreats"
                                    v-bind:value="retreat.id"
                                    v-bind:key="retreat.id"
                            >
                                {{retreat.rtrname}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group card">
                        <div class="card-header">Права доступа</div>
                        <div class="card-body">
                            <table class="table">
                                <tr v-for="(text, index) in  menus" :key="index">
                                    <td>
                                        {{index}}. {{text}}
                                    </td>
                                    <td>
                                        <label class="switch ">
                                            <input type="checkbox" class="primary" v-bind:checked="getValue(index)"
                                                   v-on:change="setValue($event.target.checked, index)">
                                            <span class="slider round"></span>

                                        </label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="card-footer">
                            <label class="switch" style="float: right;margin-right: 10px">
                                <input type="checkbox" class="primary" v-model="admin.active">
                                <span class="slider round"></span>

                            </label>
                            Активен
                        </div>
                    </div>

                    <div class="form-group float-right">
                        <button class="btn btn-primary" type="submit">Сохранить</button>
                    </div>
                </div>

            </div>
        </div>
    </form>

</template>

<script>

  export default {
    data: function () {
      return {
        admin: {
          login: '',
          password: '',
          active: 1,
          permitions: 0
        },
        retreats: [],
        aid: 0,
        exists: false,
        menus: {
          1: 'Доступ по UID, проведение регистрации, просмотр своей статистики, перевод средств, проведение предоплаченной дневной регистрации',
          2: 'расширенный поиск изменениие данных служителя, добавление служителя, добавление города, церкви',
          3: 'Право изменить регистрацию в прошлые дни ретрита',
          4: 'работа вне ретрита, предварительная регистрация, отказ от рассылок, установка "комманда", выставление счетов',
          5: 'служба перевода',
          6: 'доступ к статистике посещения ретрита',
          7: 'Express регистратор',
          8: 'настройки ретрита, баз, прочего'

        }
      }
    },
    methods: {
      save: async function () {
        console.log('save');
        try {
          console.log(this.admin);
          if (this.aid > 0) {
            await this.patchData(`/admin/admins/${this.admin.id}`, this.admin);
          } else {
            await this.postData(`/admin/admins`, this.admin);
          }
          if (this.aid === this.admin.id) {
            this.$session.set('permitions', this.admin.permitions);
          }

          this.$router.push('/admin/admins');
        } catch (e) {
          console.error(e);
        }
      },
      setBit(bit) {
        this.admin.permitions |= this.getMask(bit);
      },
      resetBit(bit) {
        this.admin.permitions &= ~this.getMask(bit);
      },
      getValue(bit) {
        return this.isPermited(bit);
      },
      isPermited(bit) {
        return (this.admin.permitions & this.getMask(bit)) > 0;
      },
      setValue(state, bit) {
        state ? this.setBit(bit) : this.resetBit(bit);
      }
    },
    async mounted() {
      this.aid = Number.parseInt(this.$route.params.id);

      const { body } = await this.getData(`/admin/admins/${this.aid}`);
      this.retreats = body.retreats;
      if (this.aid === 0) {
        return;
      }
      this.admin = body.admin;
      this.exists = body.admin.id > 0;
    }

  }
</script>

<style lang="scss" scoped>
    /*@import '~bootstrap/scss/bootstrap.scss';*/

    .switch {
        float: left;
    }
</style>
