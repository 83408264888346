<template>
    <div class="content">
        <h4 class="text-center">Редактирование скидки <b>{{data.name}}</b></h4>
        <div class="row">
            <form class="onerow" @submit.prevent="save">
                <div class="form-group ">
                    <label class="label text-uppercase">Наименование</label>
                    <input class="form-control" v-model.trim="data.name" type="text">
                </div>
                <div class="form-group">
                    <label class="label text-uppercase">Применяется</label>
                    <select class="form-control" v-model="data.applyTo">
                        <option value="0">ко дню</option>
                        <option value="1">к ночи</option>
                    </select>
                </div>
                <table class="form-group table table-borderless">
                    <tr>
                        <td>Член КЕМО</td>
                        <td>
                            <label class="switch ">
                                <input type="checkbox" class="primary" v-bind:checked="getValue(1)"
                                       v-on:change="setValue($event.target.checked, 1)">
                                <span class="slider round"></span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Предварительная регистрация
                        </td>
                        <td>
                            <label class="switch ">
                                <input type="checkbox" class="primary" v-bind:checked="getValue(2)"
                                       v-on:change="setValue($event.target.checked, 2)">
                                <span class="slider round"></span>
                            </label>
                        </td>
                    </tr>

                </table>
                <div class="form-group">
                    <label class="label text-uppercase">Минимальный возраст</label>
                    <input class="form-control" v-model="data.minAge" type="number">
                </div>
                <div class="form-group">
                    <label class="label text-uppercase">Максимальный возраст</label>
                    <input class="form-control" v-model="data.maxAge" type="number">
                </div>
                <div class="form-group">
                    <label class="label text-uppercase">Сумма</label>
                    <input class="form-control" v-model="data.amount" type="number">
                </div>
                <div class="form-group row" style="padding-top: 15px">
                    <div class="col-5 offset-1">
                        <router-link class="btn btn-secondary " to="/admin/discounts">Отмена</router-link>
                    </div>
                    <div class="col-5 offset-1">
                        <button class="btn btn-success" type="submit">Сохранить</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'editDiscount',
    data: function () {
      return {
        id: 0,
        data: {
          name: '',
          applyTo: null,
          conditions: 0,
          minAge: 0,
          maxAge: 0,
          amount: 0
        }
      }
    },
    methods: {
      save: async function() {
        this.data.applyTo = Number.parseInt((this.data.applyTo));
        if (Number.isNaN(this.data.applyTo)) {
          return;
        }
        if (this.data.name.length === 0) {
          return;
        }
        try {
            if (this.id > 0) {
                await this.putData(`/admin/discount/${this.id}`, this.data);
            } else {
              await this.postData('/admin/discount', this.data);
            }
          this.$router.push('/admin/discounts');
        } catch (e) {
          console.error(e);
        }
      },
      getValue(bit) {
        return (this.data.conditions & this.getMask(bit)) > 0;
      },
      setValue(state, bit) {
        state ? this.setBit(bit) : this.resetBit(bit);
      },
      setBit(bit) {
        this.data.conditions |= this.getMask(bit);
      },
      resetBit(bit) {
        this.data.conditions &= ~this.getMask(bit);
      }
    },
    async mounted() {
      this.id = Number.parseInt(this.$route.params.id);
      if (this.id === 0) {
        return;
      }
      const { body } = await this.getData(`/admin/discount/${this.id}`);
      console.log(body);
      this.data = body;
    }

  }
</script>

<style lang="scss" scoped>

</style>
